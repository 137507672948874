export const vueRoutes = Object.freeze({
  marketplace: { name: 'marketplace' },
  realEstateCreate: { name: 'real-estate-create' },
  realEstateEdit:{name:'real-estate-edit'},
  createFormSalesProposal: { name: 'create-form-sales-proposal' },
  settings: { name: 'settings' },
  about: { name: 'about' },
  settingsAbout: { name: 'settings-about'},
  FAQ: { name: 'FAQ' },
  contacts: { name: 'contacts' },
  techSupport: { name: 'tech-support' },
  faq: { name: 'faq' },
  kyc: { name: 'kyc' },
  profile: { name: 'profile' },
  realEstate: { name: 'real-estate' },
  ownedRealEstate: { name: 'owned-real-estate' },
  realEstateOnSale: { name: 'real-estate-on-sale' },
  realEstateInstallment: { name: 'real-estate-installment' },
  realEstateWithdrawn: { name: 'real-estate-withdrawn' },
  realEstateAll: { name: 'real-estate-all' },
  cards: { name: 'cards' },
  myEstate: { name: 'my-estate' },
  realEstateDetails: { name: 'real-estate-details' },
  marketplaceRealEstateDetails: { name: 'marketplace-real-estate-details' },
  partPurchase: { name: 'part-purchase' },
  createOffer: { name: 'create-offer' },
  withdrawn: { name: 'withdrawn' },
  currency: { name: 'currency' },
  currencyUsd: { name: 'currency-usd' },
  currencyRe: { name: 'currency-re' },
  transactionHistory: { name: 'transaction-history' },
  notFound: { name: 'not-found' },

  //authRoutes
  authorization: { name: 'authorization' },
  login: { name: 'login' },
  registration: { name: 'registration' },
  recovery: { name: 'recovery' },
  recoveryPrivateData: { name: 'recovery-private-data' },
  registrationCode: { name: 'registration-code' },
  mnemonic: { name: 'mnemonic' },
  authKYC: { name: 'auth-kyc' },

});
