<template>
  <Notification />
  <div class="app__container" :class="containerClasses">
    <router-view name="Authorization" />
    <router-view name="UnauthorizedPanel" />
    <router-view name="Sidebar" />
    <MobileMenu />
    <div class="container-content" :class="containerContentClasses">
      <router-view />
    </div>
  </div>
</template>
<script>
import { watch, computed, onMounted } from 'vue';
import WalletService from '@/js/services/wallet.service';
import MobileMenu from '@/vue/components/MobileMenu/MobileMenu';
import Notification from '@/vue/components/common/notification/Notification';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { useStore } from 'vuex';
import api from '@/js/services/api';
import authRoutes from '@/router/authRoutes';
import { vueRoutes } from '@/router/router-const';
import {sendRequest} from "@/js/helpers/log-out-request";

export default {
  name: 'Main',
  components: { MobileMenu, Notification },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const wallet = computed(() => store.getters['aut\h/getPrivateData']);
    const verificationStatus = ref(0);
    const route = useRoute();

    const setUserData = async () => {
      if (isLoggedIn.value) {
        await fetchVerificationStatus();
        await fetchAvatar();
        await fetchBalances();
      }
    };

    const isAuthorizationPage = computed(() => {
      return authRoutes.some((authorizationRoute) => {
        return route.path.includes(authorizationRoute.path);
      });
    });

      let intervalId = setInterval(async () => {
        if (!store.state.auth.user.id){
          return
        }
        const resp = await sendRequest(store.state.auth.user.id);
        if (resp==="role changed"){
          clearInterval(intervalId);
          intervalId = null;
          return false;
        }
        if (resp === 404) {
          clearInterval(intervalId);
            await store.dispatch('logout');
            location.reload();
        }
      }, 5000);
    const verifyKycStatus = async () => {
      await store.dispatch('roles/fetchRolesData', store.state.auth.user.id);
    };

    const containerClasses = computed(() => {
      return {
        'app__container--margin':
          !isLoggedIn.value && !isAuthorizationPage.value,
      };
    });

    const containerContentClasses = computed(() => {
      return {
        'container-content--w100':
          !isLoggedIn.value || isAuthorizationPage.value,
      };
    });

    const fetchBalances = async () => {
      if (wallet.value) {
        await store.dispatch(
          'balances/fetchAssetBalances',
          wallet.value.wallet
        );
      }
    };
    const fetchReBalances = async () => {
      await store.dispatch('balances/fetchReBalances')
    }

    const fetchVerificationStatus = async () => {
      const response = await api.get(
        `identity/v1/roles/${store.state.auth.user.id}`
      );
      verificationStatus.value = response.data.role;
      await store.dispatch(
        'auth/setVerificationStatus',
        verificationStatus.value
      );
    };

    const fetchAvatar = async () => {
      try {
        const resp = await api.get(
          'identity/v1/kyc/avatar/' + store.state.auth.user.id
        );
        await store.dispatch('images/addAvatar', resp.data.key);
      } catch (error) {
        console.error(error);
      }
    };

    const handleAuthState = async () => {
      if (isLoggedIn.value) {
        try {
          const response = await api.get('keys/v1/keys');
          if (response.data.data) {
            const keyData = response.data.data[0];
            WalletService.setPrivateData(keyData);
            await verifyKycStatus();
            await store.dispatch('auth/setPrivateData', keyData);
            if (keyData.recovered === true) {
              await router.push(vueRoutes.recoveryPrivateData);
            }
            if (store.getters['roles/kycProceeded']) {
              await router.push(vueRoutes.marketplace);
            } else if (!store.getters['roles/kycProceeded']) {
              await router.push(vueRoutes.authKYC);
            }
          } else {
            await router.push(vueRoutes.mnemonic);
          }
        } catch (error) {
          await store.dispatch('notification/addNotification', {
            type: 'warning',
            code: error.response.data.status_code,
            message: error.response.data.error_key,
          });
        }
      }
    };

    onMounted(async () => {
      await fetchReBalances();
      if (isLoggedIn.value) {
        await setUserData();
        await verifyKycStatus();
      }
    });
    const handleAfterLogin = async () => {
      await handleAuthState();
      await setUserData();
      await verifyKycStatus();
    };
    watch(isLoggedIn, handleAfterLogin);

    return {
      isLoggedIn,
      verificationStatus,
      wallet,
      containerClasses,
      containerContentClasses,
    };
  },
};
</script>

<style lang="scss">
@import '@/scss/app.scss';
@import './scss/variables';
@import './scss/mixins';

#app {
  display: flex;
  align-items: stretch;
  overflow-x: hidden;
  flex: 1;
  min-height: 100vh;
}

.app__container {
  display: flex;
  align-items: stretch;
  overflow-x: hidden;
  flex: 1;

  &--margin {
    margin-top: 8rem;
  }
}

.container-content {
  position: relative;
  margin-left: $sidebar-width;
  width: calc(100% - $sidebar-width);
  @include respond-to($sidebar-hide-bp) {
    margin-left: 0;
    width: 100%;
  }
  &--w100 {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
</style>
