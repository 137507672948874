import { createStore } from 'vuex';
import { auth } from './auth.module';
import { images } from '@/vuex/modules/images';
import { notification } from './modules/notification';
import { balances } from '@/vuex/modules/balances';

import AuthService from '@/js/services/auth.service';
import { roles } from '@/vuex/modules/roles';
import RolesService from '@/js/services/roles.service';
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    logout({ commit }) {
      commit('auth/logout');
      RolesService.removeIsKycProceeded();
      AuthService.logout();
    },
  },
  modules: {
    auth,
    notification,
    images,
    balances,
    roles,
  },
});
