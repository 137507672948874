export const formatBalance = (balance) => {
//   return 0;
// }
//   Round the balance to 2 decimal places and split it into an array of strings
  const balanceParts = balance.toFixed(2).toString().split('.');

  // Format the integer part of the balance
  const integerPart = balanceParts[0];
  const reversedIntegerArray = integerPart.split('').reverse(); // Reverse the order of the digits
  const integerWithSpaces = reversedIntegerArray.reduce((acc, digit, index) => {
    // Add a space after every third digit
    if (index > 0 && index % 3 === 0) {
      acc += ' ';
    }
    return acc + digit;

  }, ''); // Join the digits together with spaces
  const formattedIntegerPart = integerWithSpaces.split('').reverse().join(''); // Reverse the order back to original

  // Join the formatted integer part and decimal part back together
  const formattedBalanceParts = [formattedIntegerPart, balanceParts[1]];
  const formattedBalance = formattedBalanceParts.join('.'); // Add the decimal point back in

  return formattedBalance + ' USD'; // Append the currency symbol to the end of the string
};

export const formatBalanceRE = (balance) => {
  // Round the balance to 2 decimal places and split it into an array of strings
  const balanceParts = balance.toFixed(2).toString().split('.');

  // Format the integer part of the balance
  const integerPart = balanceParts[0];
  const reversedIntegerArray = integerPart.split('').reverse(); // Reverse the order of the digits
  const integerWithSpaces = reversedIntegerArray.reduce((acc, digit, index) => {
    // Add a space after every third digit
    if (index > 0 && index % 3 === 0) {
      acc += ' ';
    }
    return acc + digit;
  }, ''); // Join the digits together with spaces
  const formattedIntegerPart = integerWithSpaces.split('').reverse().join(''); // Reverse the order back to original

  // Join the formatted integer part and decimal part back together
  const formattedBalanceParts = [formattedIntegerPart, balanceParts[1]];
  const formattedBalance = formattedBalanceParts.join('.'); // Add the decimal point back in

  return formattedBalance + ' RE'; // Append the currency symbol to the end of the string
};

export const formatBalanceTrue = (balance) => {
  // Round the balance to 2 decimal places and split it into an array of strings
  const balanceParts = balance.toFixed(2).toString().split('.');

  // Format the integer part of the balance
  const integerPart = balanceParts[0];
  const reversedIntegerArray = integerPart.split('').reverse(); // Reverse the order of the digits
  const integerWithSpaces = reversedIntegerArray.reduce((acc, digit, index) => {
    // Add a space after every third digit
    if (index > 0 && index % 3 === 0) {
      acc += ' ';
    }
    return acc + digit;
  }, ''); // Join the digits together with spaces
  const formattedIntegerPart = integerWithSpaces.split('').reverse().join(''); // Reverse the order back to original

  // Join the formatted integer part and decimal part back together
  const formattedBalanceParts = [formattedIntegerPart, balanceParts[1]];
  const formattedBalance = formattedBalanceParts.join('.'); // Add the decimal point back in

  return formattedBalance; // Append the currency symbol to the end of the string
};
