<template>
  <div
    v-click-outside="() => (isOpen = false)"
    class="select-field"
    :class="{ active: isOpen }"
    @click="isOpen = !isOpen"
  >
    <div class="select-field__toggle flex-center">
      <div class="select-field__toggle-selected-wrp">
        <div class="select-field__toggle-selected">
          {{ label }}
        </div>
        <div class="select-field__toggle-chevron">
          <span />
          <span />
        </div>
      </div>
      <div class="select-field__toggle-list">
        <div
          v-for="opt in uniqOptions"
          :key="opt.label"
          class="select-field__toggle-list-option"
          @click="changeValue(opt)"
        >
          {{ opt.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const EVENTS = {
  updateModelValue: 'update:modelValue',
};
import { ref } from 'vue';
import vClickOutside from 'click-outside-vue3';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    label: { type: String, default: '' },
    options: { type: Array, default: () => [] },
    /**
     Options array should be such a structure:
     [
        { label: 'String', value: Any },
        { label: 'String', value: Any }
     ]
     */
  },
  setup(props, context) {
    const isOpen = ref(false);
    console.log('item: ', props.options);

    const uniqOptions = ref([]);

    const filterOptions = (opt) => {
      uniqOptions.value = [];
      props.options.forEach((item) => {
        if (item.label !== opt.label) uniqOptions.value.push(item);
      });
    };

    filterOptions(props.options.find((item) => item.label === props.label));

    const changeValue = (opt) => {
      context.emit(EVENTS.updateModelValue, opt.value);
      filterOptions(opt);
    };

    return { isOpen, changeValue, uniqOptions };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.select-field {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: $col-select;
  font-size: 1.6rem;
  font-weight: 400;

  &__toggle {
    flex-direction: column;
    position: relative;
    padding-left: 0.1rem;
    cursor: pointer;
    width: 100%;
  }

  &__toggle-selected {
    transition: color $transition-duration linear;

    &-wrp {
      display: flex;
      justify-content: space-between;
    }

    &::after {
      content: '';
      display: block;
      width: 0%;
      height: 2px;
      background-color: $col-text-inactive;
      transition: width $transition-duration linear;
    }
  }

  &__toggle-chevron {
    position: relative;
    top: -0.15rem;
    width: 0.8rem;
    height: 100%;
    margin-left: 0.5rem;
    transition: transform $transition-duration linear;

    & span {
      position: absolute;
      top: 42%;
      display: block;
      height: 2px;
      width: 0.525rem;
      background-color: $_arrowBlue;
      transition: background-color $transition-duration linear;

      &:first-child {
        left: 0;
        transform: rotate(45deg);
      }

      &:last-child {
        right: 0;
        transform: rotate(-45deg);
      }
    }
  }

  &.active &__toggle-chevron {
    transform: rotate(-180deg);
  }

  &__toggle-list {
    text-align: center;
    position: relative;
    flex-direction: column;
    top: -1rem;
    left: -0.65rem;
    width: 100%;
    display: none;
    color: $col-nav-bar-active;
    font-size: 1.6rem;
    font-weight: 400;
    transition: height $transition-duration linear;

    &-option {
      height: 1.8rem;
    }
  }

  &.active &__toggle-list {
    display: flex;
    padding-left: 0.1rem;
  }
}
</style>
