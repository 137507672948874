import api from '@/js/services/api';
import { useAmountConversion } from '@/vue/compositions/amount-conversation';
const { convertAmountFromServer } = useAmountConversion();
export const balances = {
  namespaced: true,
  state: {
    assetBalances: {
      USD: 0,
      EUR: 0,
      UAH: 0,
      RE: 0,
      balanceRE: 0,
      totalRE: 0,
    },
  },

  mutations: {
    updateAssetBalances(state, payload) {
      if (payload.data.length > 0) {
        payload.data.forEach(item => {
          state.assetBalances[item.asset] = convertAmountFromServer(item.value);
        });
      }
    },
    updateReBalances(state, payload) {
      if (payload) {
          state.assetBalances.totalRE = convertAmountFromServer(+payload.total);
          state.assetBalances.balanceRE = convertAmountFromServer(+payload.value);
        }
      }
  },

  actions: {
    async fetchAssetBalances({commit}, wallet) {
      const receivedData = await api.get(`tx/v1/assets/balances/${wallet}`);
      commit('updateAssetBalances', receivedData.data);
    },
    async fetchReBalances({commit} ) {

      const receivedDataRe = await api.get(`tx/v1/assets/total/RE`);
      const receivedDataEmission = await api.get(`tx/v1/assets/balances/?filter[type]=2`)
      const data = { ...receivedDataRe.data, ...receivedDataEmission.data.data[0] };
      commit('updateReBalances', data);
    },
  },
  getters: {
    getBalances(state) {
      return state.assetBalances;
    },
    getAssetBalanceByCurrency: (state) => (currency) => {
      const asset = state.assetBalances.find(item => item.asset === currency);
      return asset ? asset.value : 0; // Если валюта найдена, вернуть её значение, иначе 0
    },
  },
};
