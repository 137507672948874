import api from '@/js/services/api';
import RolesService from '@/js/services/roles.service';

export const roles = {
  namespaced: true,
  state: {
    kycProceeded: false,
    rolesData: null,
  },
  actions: {
    async fetchRolesData({ commit }, userId) {
      const response = await api.get(
        `tx/v1/roles/crr?filter[user_id]=${userId}`
      );
      if (response.data.data && response.data.data.length > 0) {
        await commit('setKycProceeded', true);
      }
      commit('setRolesData', response.data);
    },
    updateKycProceeded({ commit }, value) {
      commit('setKycProceeded', value);
    },
  },
  mutations: {
    setKycProceeded(state, value) {
      state.kycProceeded = value;
      RolesService.setKycProceedStatus(value);
    },
    setRolesData(state, data) {
      state.rolesData = data;
    },
  },

  getters: {
    kycProceeded: (state) => state.kycProceeded,
    rolesData: (state) => state.rolesData,
  },
};
